.allItems {
    border: none;
}

.footerSec {
    text-align: center;
    margin-top: 43px;
    font-family: ui-monospace;
}

.footerSec p {
    font-size: 17px;
}

p.metaText {
    margin-top: 25px;
    color: #000000bd;
}

p.copyrightTxt {
    margin-top: 43px;
    font-family: monospace;
}