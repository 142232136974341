.coverWrapper {
    /* background: url('https://static.wixstatic.com/media/11062b_b35e39e734284486a1293ef3c6a3145b~mv2.jpg/v1/fill/w_1903,h_817,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_b35e39e734284486a1293ef3c6a3145b~mv2.jpg'); */
    background:url("../Image/coveRUpdate.jpg");
    padding: 149px 0px 60px 0px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0px;
    border-bottom: 2px solid #000000ab;
    border-top: 2px solid #000000ab;
}

h2.textContentWrapper {
    color: black;
    font-size: 3.5vw;
    margin-bottom: 135px;
    letter-spacing: 11px;
    font-family: fantasy;
}

.coverText {
    font-size: 3.5vw;
    font-weight: bold;
    color: black;
    margin-bottom: 40px;
    letter-spacing: 4px;
    font-family: serif;
}

@media (max-width: 767px) {
    h2.textContentWrapper, .coverText {
        font-size: 6vw; 
        
    }
}


/* .storyMeta {
    text-align: center;
    font-family: a !important;
    font-size: 19px;
}

section.storyOfkomofar h6 {
    text-align: center;
    margin-top: 70px;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 5px;
}

p.storyMetaLarge {
    text-align: center;
    width: 830px;
    margin: auto;
    line-height: 29px;
    font-family: a !important;
    font-size: 18px;
    color: #000000b8;
    margin-bottom: 31px;
} */



section.storyOfkomofar h6 {
    margin-top: 70px;
    font-weight: 700;
    font-size: 50px;
    letter-spacing: 5px;
    text-align: center;
}

p.storyMeta, p.storyMetaLarge {
    text-align: center; 
}

p.storyMeta {
    font-size: 1.2rem; 
}

p.storyMetaLarge {
    max-width: 830px;
    margin: auto;
    line-height: 2;
    color: #000000b8;
    margin-bottom: 31px;
    font-family: none;
    font-size: 18px;
}


.recipeWrapper{
    background: url('https://static.wixstatic.com/media/11062b_23d4de1d2821481784e813dd4ecd83b5~mv2.jpeg/v1/fill/w_1903,h_560,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_23d4de1d2821481784e813dd4ecd83b5~mv2.jpeg');
    /* padding: 272px  0px; */
    padding: 426px 0px 110px 0px;
    background-attachment: local;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0px;
}

section.getintouchWrapper {
    margin-top: 52px;
}

section.getintouchWrapper h2 {
    font-family: m!important;
    font-size: 42px;
    margin-bottom: 16px;
    color: #000000bf;
}

section.getintouchWrapper p {
    font-family: auto !important;
    font-size: 17px;
    color: #000000ad;
}

section.getintouchWrapper input {
    border: none;
    border-bottom: 1px solid #00000080;
    margin-bottom: 20px;
    border-radius: 0px;
}
section.getintouchWrapper button {
    font-family: auto !important;
    font-size: 19px;
}
section.subscribeWrapper h3 {
    text-align: center;
}
section.subscribeWrapper h3 {
    text-align: center;
    font-family: auto !important;
    font-size: 40px;
    margin-bottom: 28px;
}

section.subscribeWrapper {
    margin-top: 50px;
}
section.subscribeWrapper input {
    padding: 12px 90px 16px 90px;
    background: #ff8f8f;
    border: 2px solid #ed4e4e;
    border-radius: 8px;
}

section.subscribeWrapper button {
    padding: 17px 31px 13px 28px;
    font-family: auto !important;
    font-size: 14px;
    border-radius: 8px;
}
/* .inputWrapper {
    width: 457px;
    margin: auto;
}
.inputWrapper {
    display: flex;
  }
  
  @media (max-width: 576px) {
    .inputWrapper button {
      display: none;
    }
  } */
  .inputWrapper {
    margin: auto;
}
.storyOfkomofar {
    background:#dddddd5c;
    padding: 15px 0px;
}
h3.footerKomo {
    font-family: auto !important;
    margin-top: 120px;
}
.map-wrapper {
    margin-top: 50px;
    position: relative;
    height: 440px;
}
  
  .map-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
