.card.productCard button {
    border-radius: 19px;
}
.productCard {
    border: none;
  }
  .productCard {
    border: none;
}
.card.productCard h5 {
    font-weight: 800;
    color: black;
}
.card.productCard button {
    font-weight: 900;
    color: white;
    background:black;
}