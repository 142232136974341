@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&amp;display=swap');

.d-none{
  display: none;
}

body {
  background:#fcfcfc !important;
  font-size: 14px;
  /* font-family: 'Poppins', sans-serif !important; */
}
/* h1,h2,h3,h4,h5,h6,button,input,textarea{
  font-family: 'Poppins', sans-serif !important;
} */
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
}

p{
  font-size: 14px;
  /* font-family: 'Poppins', sans-serif !important; */
}
a.side-bar-item {
  text-decoration: none;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  /* font-family: 'Poppins', sans-serif !important; */
}

a.text-center.ms-3.h6.animated.fadeInUp {
  text-decoration: none;
}

.regbutton {
  margin-bottom: 20px;
}

a.loginText {
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.swal2-styled:focus{
  box-shadow: none !important;
}

span.navbar-brand span {
  color: #f01484;
  font-size: 22px;
}

@import "sidebar.css";
@import "progress.css";
@import "dropdownmenu.css";