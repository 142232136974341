.headingContainsWrapper h6 {
    font-size: 27px;
    font-weight: 900;
    text-align: center;
    margin-top: 28px;
}
.headingContainsWrapper p {
    text-align: center;
}
.headingContainsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
}
.headingContainsWrapper img {
    max-width: 100%;
}
.headingContainsWrapper p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 41px;
}
.center-nav {
    display: flex;
    justify-content: center;
}
a.hvr-float.loginInfo.nav-link {
    font-size: 20px;
    font-family: a !important;
    margin-top: -9px;
}

a.hvr-float.cartInfo.nav-link {
    font-size: 25px;
    font-family: a !important;
    margin-top: -17px;
}

.counterCircle {
    background: #f01484;
    color: white;
    width: 25px;
    text-align: center;
    border-radius: 100%;
}
.headingContainsWrapper img {
    width: 45px;
}
