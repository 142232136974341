.imageDetails img {
    border: 1px solid #ddd;
    padding: 5px;
}

.detailsWrapper h3 {
    font-family: none;
    color: #0000009e;
}

.detailsWrapper p {
    font-size: 21px;
    color: #0000009e;
}

.detailsWrapper span {
    color: #0000009e;
}

.detailsWrapper input {
    width: 66px;
    padding: 10px 7px;
    margin-bottom: 20px;
}

.detailsWrapper button {
    width: 210px;
    padding: 12px 0px;
    background: #0000009e;
    color: #ffffffd9;
    border: none;
    margin-bottom: 40px;
}

.socialMediaWrapper span {
    font-size: 19px;
    margin-right: 22px;
}
section.AllDetailsWrapper {
    margin-top: 40px;
}
.detailsWrapper h3 {
    margin-top: 30px;
}