/* .signupBoxWrapper {
    text-align: center;
}

.signupBoxWrapper h1 {
    font-family: initial;
}

.signupWithGoogle {
    border: 1px solid #ddd;
    padding: 9px 0px;
    font-size: 16px;
}
.signupWithFacebook{
    border: 1px solid #ddd;
    padding: 9px 0px;
    font-size: 16px;
}

.signupWithEmail{
    border: 1px solid #ddd;
    padding: 9px 0px;
    font-size: 16px;
} */
.signupBoxWrapper {
    text-align: center;
    width: 344px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.signupBoxWrapper h1 {
    font-family: none;
    font-size: 45px;
    margin-bottom: 14px;
}

.signupWithGoogle {
    border: 1px solid #ddd;
    padding: 9px 0px;
    font-size: 16px;
}
.signupWithFacebook {
    border: 1px solid #ddd;
    padding: 9px 0px;
    font-size: 16px;
    color: white;
    background: #3271f1;
}

.signupWithEmail{
    border: 1px solid #ddd !important;
    padding: 9px 0px;
    font-size: 16px;
}

.signupWithGoogle svg {
    float: left;
    font-size: 27px;
    margin-left: 10px;
}

.signupWithFacebook svg {
    float: left;
    font-size: 27px;
    margin-left: 10px;
}
.signupBoxWrapper p i {
    font-size: 18px;
    font-family: auto;
}

.crossBtn svg {
    font-size: 28px;
    position: absolute;
    top: 10%;
}
.card.productCard button {
    padding: 10px 0px;
}