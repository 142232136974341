.emailField input {
    width: 100%;
    padding: 0px 0px;
    border: none;
    border-bottom: 1px solid #00000075;
}

.passwordField input {
    width: 100%;
}

.passwordField input {
    padding: 0px 0px;
    border: none;
    border-bottom: 1px solid #00000075;
}

.emailField p {
    float: left;
    font-family: emoji;
    font-size: 19px;
}

.passwordField p {
    float: left;
    font-size: 19px;
    font-family: emoji;
}

.signupBoxWrapper button {
    width: 100%;
    padding: 13px 0px;
    margin-top: 16px;
    margin-bottom: 16px;
    background: #9595957d;
    border: none;
    font-size: 15px;
    color: white;
}
.signupWithEmailAndfacebook svg {
    font-size: 30px;
    margin-top: 10px;
}
.signupWithEmailAndfacebook svg {
    font-size: 30px;
    margin-top: 10px;
}

.signupWithEmailAndfacebook span lebel svg {
    color: #0000ff99;
}